import * as commonStyles from '../../styles/scss/components/NewWizard/WizardCommon.module.scss';

const PlayersNumberStep = (props) => {
  const {
    playersNumber,
    handleChoosePlayerNumber,
    selectedPlayerNumber,
    currentStep
  } = props
  return (
    <div className = {`${commonStyles.buttons_container} ${commonStyles.players_container}`}>
      <div className = {commonStyles.inner_container}>
        {playersNumber.map(number => {
          if (number >= 5) return
          return (
            <div 
              className = {`${commonStyles.button_wrapper}`} 
              key = {`playerNumber${number}`}
              onClick = {() => {handleChoosePlayerNumber(number, currentStep + 1)}}
              >
              <div className  = {`${commonStyles.button_container} ${selectedPlayerNumber === number ? commonStyles.active : ''} ${commonStyles.players_number}`}>
                <div className = {`${commonStyles.standart_background} ${commonStyles.container}`}></div>
                <div className = {`${commonStyles.hover_background} ${commonStyles.container}`}></div>
                <div className = {`${commonStyles.active_background} ${commonStyles.container}`}></div>
                <div className = {`${commonStyles.content_container} ${commonStyles.container}`}>
                  <h5 className = {commonStyles.number_container}>{number}</h5>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PlayersNumberStep