import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { connect } from 'react-redux';
import { login } from '../../store/auth/authActions';
import { toggleSidebar } from '../../store/modals/modalsActions';
import {sendStatsToBackend} from '../../store/static/staticInfoActions'
import * as styles from '../../styles/scss/components/Landing/Landing.module.scss';
import { toggleBackdropState } from '../../store/backdropState/backdropStateActions';
import {toggleIsCookiePopupHidden} from '../../store/toggles/toggleStateActions.js'
import { useRouter } from 'next/router';
import { setChosenAvatarById } from '../../store/avatars/avatarsActions';
import FormInput from '../FormInput/FormInput';
import useTranslation from 'next-translate/useTranslation';
import {getRouterQuery} from '../../helpers/GetQueryParams'
import { getUrl } from '../../helpers/navigation';
import DogImage from '../../public/images/landing/cookiePopup.svg'
import { motion } from "framer-motion"

const Landing = (props) => {
  const {
    onLoginRequest,
    onToggleSidebar,
    landingType,
    onToggleBackdropState,
    textContent,
    onSendStatToBackend,
    onToggleIsCookiePopupHidden
  } = props

  const {isCookiePopupHidden} = useSelector(state => state.toggles)
  const [password, setPassword] = useState('');
  const mailRef = useRef();
  const passwordRef = useRef();
  const {t} = useTranslation()
  const [passworInputType, setPassowrdInputType] = useState(true)

  const router = useRouter()

  const [email, setEmail] = useState({
    value: '',
    valid: true,
    errorText: '',
    regEx: /^[a-zA-Z0-9.@_-]{2,}$/,
    changed: false
  })

  const handleChange = (e) => {
    e.preventDefault();
    switch (e.target.name) {
      case 'email': {
        const valid = email.regEx.test(e.target.value.trim());
        if (!valid) {
          setEmail(prevState => {
            return {
              ...prevState,
              value: e.target.value.trim(),
              changed: true,
              valid: false,
              errorText: t('errors:email_or_username.invalid'),
            }
          })
        } else {
          setEmail(prevState => {
            return {
              ...prevState,
              value: e.target.value.trim(),
              changed: true,
              valid: true,
            }
          })
        }
      }; break;
    }
  }

  const handleLogin = (e) => {
    e.preventDefault();
    const credentials = {
      email: email.value,
      password: password
    }
    onLoginRequest(credentials)
    onSendStatToBackend({type: "CLICK_BUTTON_SIGN_IN", data: isCookiePopupHidden || localStorage.getItem('isCookiePopupHidden') ? "COOKIES_ACCEPTED": "COOKIES_NOT_ACCEPTED"})
  }

  useEffect(() => {
    if (mailRef.current && mailRef.current.value.length > 0) {
      handleChange(mailRef.current)
    }
    if (passwordRef.current && passwordRef.current.value.length > 0) {
      setPassword(passwordRef.current.value)
    }
  }, [])

  useEffect(() => {
    checkLocalstorageForReferrals();
  }, [router])
  useEffect(() => {
    const query = getRouterQuery(router)
   if(query.referralCode || query.rc) {
    onToggleSidebar({
      isOpen: true,
      sidebarType: 'signUp',
      hasTitle: true,
      showSidebarCloseButton: true,
      sidebarData: {email: query.email ? query?.email?.replace('%40', '@'): ''}
    })
   }
  }, [])
  const checkLocalstorageForReferrals = () => {
    const rc = router.query.referralCode || router.query.rc;
    if(rc) {
      let referralCode = localStorage.getItem('referralCode');
      if(!referralCode || rc != referralCode) {
        localStorage.removeItem('referralCode');
        localStorage.setItem('referralCode', rc);
      }
    }
    const ai = router.query.avatarId || router.query.ai;
    if(ai) {
      let avatarId = localStorage.getItem('avatarId');
      if(!avatarId || ai != avatarId) {
        localStorage.removeItem('avatarId');
        localStorage.setItem('avatarId', ai);
      }
    }
  }

  const handleLoginClick = (e) => {
    const match = window.matchMedia("(min-width: 576px)").matches

    if (match) {
      console.log('match')
      
      if(window.matchMedia("(orientation: landscape)").matches){
        console.log('window.matchMedia("(orientation: landscape)").matches')
        if(window.matchMedia("(min-width: 992px)").matches){
          console.log('window.matchMedia("(min-width: 992px)").matches')
          handleLogin(e)
        } else {
          console.log('Not window.matchMedia("(min-width: 992px)").matches')
          onToggleBackdropState(true)
          onSendStatToBackend({type: "CLICK_BUTTON_POPUP_SIGN_IN", data: isCookiePopupHidden || localStorage.getItem('isCookiePopupHidden') ? "COOKIES_ACCEPTED": "COOKIES_NOT_ACCEPTED"})
          onToggleSidebar({
            isOpen: true,
            sidebarType: 'logIn',
            hasTitle: true,
            showSidebarCloseButton: true
          })
        }
      } 

      else {
        console.log('Not window.matchMedia("(orientation: landscape)").matches')
        if(window.matchMedia("(max-width: 768px)").matches){
          if(window.matchMedia("(min-height: 1024px)").matches){
            handleLogin(e)
          } else {
            onToggleSidebar({
              isOpen: true,
              sidebarType: 'logIn',
              hasTitle: true,
              showSidebarCloseButton: true
            })
          }
        } else {
          handleLogin(e)
        }
      }
    } 
    
    else {
      if(window.matchMedia("(min-height: 1024px)").matches){
        handleLogin(e)
      } else {
        onToggleBackdropState(true)
        onSendStatToBackend({type: "CLICK_BUTTON_POPUP_SIGN_IN", data: isCookiePopupHidden || localStorage.getItem('isCookiePopupHidden') ? "COOKIES_ACCEPTED": "COOKIES_NOT_ACCEPTED"})
        onToggleSidebar({
          isOpen: true,
          sidebarType: 'logIn',
          hasTitle: true,
          showSidebarCloseButton: true
        })
      }

    }
  }

  const CookiePopup = () => {
    return (
      <motion.div className={styles.cookiePopup_container}
      initial = {{ y: 500 }}
      animate = {{ y: 0 }}
      transition = {{ duration: .7 }}
      >
        <div className={styles.cookiePopup_innerContainer}>
          <div className={styles.cookiePopup_dogImage}>
            <DogImage />
          </div>
          <p className={styles.cookiePopup_title}>Cookies</p>
          <p className={styles.cookiePopup_text}>
            {t('landing:cookiePopup')}
          </p>
          <button className={styles.cookiePopup_button} 
          onClick={() => {
            onToggleIsCookiePopupHidden()
            onSendStatToBackend({type: "CLICK_BUTTON_COOKIE_ACCEPT", data: "COOKIES_ACCEPTED"})
            localStorage.setItem('isCookiePopupHidden', 'true')
          }}>
            {t('common:buttons.accept')}
          </button>
        </div>
      </motion.div>
    )
  }

  return (
    <>
      <section className = {`${styles.landing_page} ${styles[landingType]}`}>
        <div className = {styles.landing_container}>
          <div className = {styles.landing_top_content}>
            {landingType === 'typeA' && (
              <div className = {`${styles.landing_left} ${styles.landing_side}`}>                
                <div className = {`${styles.landing_left_content} ${styles.lading_side_content}`}>
                  <div className={styles.information_outer_container}>
                    <div className = {styles.information}>
                      <div className={styles.information_container}>
                        <h1 className = {styles.page_title}>
                          {textContent.header}
                        </h1>
                        <p className = {styles.page_description}>
                          {textContent.subHeader}
                        </p>
                      </div>
                    </div>
                    <div className = {styles.authorizaion}>
                      <form autoComplete = 'off' onSubmit= {handleLogin}>
                        <div className = {`${styles.form_group} ${styles[landingType]}`}>
                          <FormInput
                            type = 'text'
                            value = {email.value}
                            name = 'email'
                            change = {handleChange}
                            active = {email.value.length > 0}
                            labelText = {textContent.login_email_or_name_input}
                            showIndicator = {true}
                            id = 'signupEmail'
                            valid = {email.valid}
                            errorText = {email.errorText}
                            changed = {email.changed}
                          />
                        </div>
                        <div className = {`${styles.form_group} ${styles[landingType]}`}>
                          <FormInput 
                            type = {passworInputType ? 'password' : 'text'}
                            value = {password}
                            name = 'password'
                            change = {(e) => setPassword(e.target.value)}
                            active = {password.length > 0}
                            labelText = {textContent.password_input}
                            showIndicator = {false}
                            id = 'signinPassword'
                            showIcon = {true}
                            iconFunction = {() => {setPassowrdInputType(prevState => !prevState)}}
                            valid = {true}
                          />
                        </div>
                        <div className = {`${styles.form_group} ${styles.form_text}`}>
                          <span className={styles.forget_typeA}  onClick = {() => {
                            onToggleSidebar({
                              isOpen: true,
                              sidebarType: 'forgotPassword',
                              hasTitle: true,
                              showSidebarCloseButton: true
                              })
                            }}
                            >
                            {textContent.forget_password}
                          </span>
                        </div>
                        <div className = {`${styles.buttons} ${styles.form_group}`}>
                          <button className = {`${styles.button} ${styles.log_in}`} type = 'button' onClick={handleLoginClick}>{textContent.login_button}</button>
                          <button className = {`${styles.button} ${styles.sign_up}`} type = 'button' onClick = {() => {
                            onSendStatToBackend({type: "CLICK_BUTTON_POPUP_SIGN_UP", data: isCookiePopupHidden || localStorage.getItem('isCookiePopupHidden') ? "COOKIES_ACCEPTED": "COOKIES_NOT_ACCEPTED"})
                            onToggleSidebar({
                              isOpen: true,
                              sidebarType: 'signUp',
                              hasTitle: true,
                              showSidebarCloseButton: true,
                            })
                          }} >{textContent.signup_button}</button>
                          <button type='submit' style={{display: 'none'}}></button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )
            }
          </div>
        </div>
      </section>
      <div className={styles.privacy}>
        <Link href={getUrl(router.locale, 'privacy')}>
            <a>{t('common:sidebar_body.sign_up.terms_and_conditions')}</a>
        </Link>
      </div>
      {!isCookiePopupHidden && !localStorage.getItem('isCookiePopupHidden') && CookiePopup()}
    </>
  )
}


const mapStateToProps = (state) => {
  return {
    landingType: state.settings.landingType
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoginRequest: (credentials) => dispatch(login(credentials)),
    onToggleSidebar: (sidebarState) => dispatch(toggleSidebar(sidebarState)),
    onToggleBackdropState: (state) => dispatch(toggleBackdropState(state)),
    onChoseAvatarWithId: (avatarId) => dispatch(setChosenAvatarById(avatarId)),
    onSendStatToBackend: (payload) => dispatch(sendStatsToBackend(payload)),
    onToggleIsCookiePopupHidden: () => dispatch(toggleIsCookiePopupHidden())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Landing)