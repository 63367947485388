import * as commonStyles from '../../styles/scss/components/NewWizard/WizardCommon.module.scss';
import Avatar from '../Avatar/Avatar';
const FriendsStep = (props) => {
  const {
    friendsList,
    handleFriendsChoose, 
    friendIsSelected,
  } = props

  return (
    <div className = {`${commonStyles.buttons_container} ${commonStyles.friends_container}`}>
      <div className = {commonStyles.inner_container}>
        <ul className = {commonStyles.player_select_ul}>
        {friendsList.sort((friendA, friendB) =>  friendB.friend.online - friendA.friend.online ).map((friend) => {
            return (
              <li 
                className = {commonStyles.player_select_li}
                key = {`selectFriend${friend.id}`}
                >
                <div 
                  onClick = {() => {handleFriendsChoose(friend.friend.id)}}
                  className = {commonStyles.select_friend_container}
                  >
                  <Avatar
                    mode = 'normal'
                    size = 'medium'
                    avatarUrls = {{
                      ...friend.friend.avatar
                    }}
                    hasFlag = {false}
                    active = {friendIsSelected(friend.friend.id)}
                    showOverlayOnHover = {true}
                    isOnline = {friend.friend.online}
                  />
                  {/* {friendIsSelected(friend.id) ? <p>selected</p> : ''} */}
                  <span className = {commonStyles.username}>{friend.friend.username}</span>
                </div>
              </li>
            )
          })}
        </ul>
      </div>      
    </div>
  )
}

export default FriendsStep