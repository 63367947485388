
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
// import dynamic from 'next/dynamic'
import { connect, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { SetLanguagesOnPage } from '../store/languages/languagesActions';
import Script from 'next/script'
import Landing from '../components/Landing/Landing';
import Dashboard from '../components/Dashboard/Dashboard';
import getT from 'next-translate/getT'
import Head from 'next/head';
import Loader from '../components/Loader/Loader';
// import Sidebar from '../components/SideBar/SideBar'
function Home(props) {

  const {
    meta_title, 
    meta_description, 
    header, 
    subHeader, 
    login_button, 
    signup_button, 
    login_email_or_name_input, 
    password_input,
    forget_password,
    isAuthenticated,
    gameSetup
  } = props

  return (
    <>
      <Head>
        <meta name="description" content={meta_description}/>
        <title>{meta_title}</title>
        <script
            dangerouslySetInnerHTML={{
                __html: `
                (function () {
                    window.onpageshow = function(event) {
                      if (event.persisted) {
                        window.location.reload();
                      }
                    }
                  })();
                `,
            }}
        />
      </Head>
      {process.env.NEXT_ENABLE_GTAG == 'true' && (
        <>
          <Script
          strategy='afterInteractive'
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
          />
          <Script
                strategy='afterInteractive'
                key='gtag'
                dangerouslySetInnerHTML={{
                  __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                  page_path: window.location.pathname,
                });
              `,
                }}
            />
        </>
      )}
      {process.env.NEXT_ENABLE_HOTJAR == 'true' && (
        <Script
            strategy='afterInteractive'
            key='hotjar'
            dangerouslySetInnerHTML={{
              __html: `
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:1879307,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
            }}
        />)}
      {/* <h1 className={styles.pageTitle}>{t('Home Page')}</h1> */}
      <Head>
        {/* <title>{title}</title> */}
        {/* <meta name="description" content={desc}/> */}
        {process.env.NEXT_ENABLE_GTAG == 'true' && (
          <>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}></script>
            <script
                dangerouslySetInnerHTML={{
                  __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                  page_path: window.location.pathname,
                });
              `,
                }}
            />
          </>
        )}
        {process.env.NEXT_ENABLE_HOTJAR == 'true' && (
          <script
          dangerouslySetInnerHTML={{
            __html: `
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:1879307,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
          }}
          />
        )}
      </Head>
      {isAuthenticated ?
        (
          <>
            {/* { gameSetup.dataLoaded ? <Dashboard gameSetup={gameSetup}/> : <Loader />} */}
            { gameSetup.dataLoaded ? <Dashboard gameSetup={gameSetup}/> : <Loader />}
          </>
        ) :
        (
          <Landing textContent = {{
            header, 
            subHeader, 
            login_button, 
            signup_button, 
            login_email_or_name_input,
            password_input,
            forget_password
          }}
            // landingContent={landingContent}
          />
        )
      }

      {/* <Sidebar /> */}
      </>
  )
}

// Home.getInitialProps = async function (ctx) {
//   ;
//   return {
//     meta: metaContent[ctx.locale] ?? metaContent.de,
//     landingContent: landingContent[ctx.locale],
//     buttonContent: buttonContent[ctx.locale]
//   }
// }


const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    gameSetup: state.gameSetup,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogoutRequest: () => dispatch(logout())
  }
}


// ...
 async function _getStaticProps({ locale }) {

  const t = await getT(locale, 'meta')
  return { props: {
    meta_title: t('meta_title'), 
    meta_description: t('meta_description'), 
    header: t('header'), 
    subHeader: t('subHeader'), 
    login_button: t('login_button'), 
    signup_button: t('signup_button'), 
    login_email_or_name_input: t('login_email_or_name_input'), 
    password_input: t('password_input'),
    forget_password: t('forget_password')
  }
}}

export default connect(mapStateToProps, mapDispatchToProps)(Home);


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  