import * as commonStyles from '../../styles/scss/components/NewWizard/WizardCommon.module.scss';
import CheckSvg from '../../public/images/common/check.svg';
import { CloseButton } from '../Buttons/Buttons';
import {useState, useEffect} from 'react'
import {useSelector } from 'react-redux';

import ModeStep from './ModeStep'
import PlayersNumberStepNew from './PlyersStepNumberNew.js';
import VersusStep from './VersusStep';
import RulesStep from './RulesStep';
import useTranslation from 'next-translate/useTranslation';
const PublicMM = (props) => {
  const {
    gameSetup, 
    handleGameModeChange, 
    onSetPlayerNumber, 
    onSetVersus, 
    handleStartMatchMaking,
    fromLobby,
    closeFunction,
    onResetRules
  } = props

  let [isSearchDisabled, setIsSearchDisabled] = useState(false);

  let lang = useSelector(state => state.languages.publicMM);
  let buttonsTranslations = useSelector(state => state.languages.buttons);
  let currentLang = useSelector((state) => state.languages.currentLang);
  const [translates, setTranslates] = useState(null);
  const [buttonTranslates, setButtonTranslates] = useState(null)
  const [currentStep, setNextStep] = useState(1)
  const [showExtraStep, setShowExtraStep] = useState(false)
  const {t} = useTranslation()
  const handLeGameModeChoose = (gameMode, nextStep) => {
    handleGameModeChange(gameMode)
    if (gameMode.value === 'team') {
      const matchMakingDetails = {
        mode: 'team',
        type: gameSetup.selectedGameType,
        players: 4,
        rules: [1, 2],
        versus: '2v2',
        region: 'global'
      }
      if (isSearchDisabled) {
        return
      }
      startMatchMaking(matchMakingDetails)
      return
    }
    setNextStep(nextStep)
  }

  const handleChoosePlayerNumber = (playerNumber, nextStep) => {
    if (playerNumber === undefined || playerNumber === null || playerNumber === '') {
      return
    }
    if (playerNumber > 4) {
      // alert('coming soon')
      return
    }
    onSetPlayerNumber(playerNumber)
    if (gameSetup.selectedGameMode.value === 'solo') {
      const matchMakingDetails = {
        mode: 'solo',
        type: gameSetup.selectedGameType,
        players: playerNumber,
        rules: [1, 5],
        versus: '1v1',
        region: 'global'
      }
      if (isSearchDisabled) {
        return
      }
      startMatchMaking(matchMakingDetails)
    }
    // if (playerNumber === 6 && gameSetup.selectedGameMode.value === 'team') {
    //   setShowExtraStep(true)
    // } else {
    //   setShowExtraStep(false)
    // }
    // setNextStep(nextStep)
  }

  const handleVersusSet = (versus, nextStep) => {
    onSetVersus(versus)
    setNextStep(nextStep)
  }

  useEffect(() => {
    if(currentLang && lang && buttonsTranslations) {
      setTranslates(lang[currentLang])
      setButtonTranslates(buttonsTranslations[currentLang])
    };
  }, [currentLang])

  useEffect(() => {
    if (fromLobby) {
      setNextStep()
    }
  }, [fromLobby])

  const extended = () => {
    if ((((showExtraStep && currentStep === 4) || (!(showExtraStep) && currentStep === 3)) && !fromLobby)) {
      return true
    }
    return false
  }

  const startMatchMaking = (params) => {
    setIsSearchDisabled(true)
    handleStartMatchMaking(params);
  }

  const getStepTitle = (step) => {
    switch (step) {
      case 1: return t('wizard:online_game.mode_step_desc');
      case 2: return t('wizard:online_game.players_number_step_desc');
      case 3: return t('wizard:online_game.rules_step_desc');
      default: return ''
    }
  }

  return (
    <div className = {commonStyles.wizard_container}>
      <div className = {commonStyles.upper_part}>
        <div className = {commonStyles.wizard_head}>
          <div className = {commonStyles.wizard_details_container}>
            <div className = {`${commonStyles.wizard_image_container} ${commonStyles.classic}`}>
              <img className = {commonStyles.wizard_image} src = {`/images/dashboard/classic_bottom.svg`} />
            </div>
            <div className = {commonStyles.wizard_title_container}>
              <h2 className = {commonStyles.wizard_title}>{t(`wizard:online_game.title`)}</h2>
            </div>
          </div>
          <div className = {commonStyles.wizard_close_button_container}>
            <CloseButton click = {closeFunction} />
          </div>
        </div>
        <div className = {commonStyles.dynamic_part}>
          <h4 className = {commonStyles.dynamic_title}>{getStepTitle(currentStep)}</h4>
          <div className = {commonStyles.dynamic_body}>
            {currentStep === 1 && (
              <ModeStep 
                gameModes = {gameSetup.gameModes} 
                handLeGameModeChoose = {handLeGameModeChoose}
                selectedGameMode = {gameSetup.selectedGameMode}
                currentStep = {currentStep}
                translates = {translates}
                currentlySearchingPLayersNumber = {{
                  team: gameSetup.playersSearching[3],
                  solo: gameSetup.playersSearching[0] + gameSetup.playersSearching[1] + gameSetup.playersSearching[2]
                }}
              />
            )}
            {currentStep === 2 && (
              <PlayersNumberStepNew
                playersNumber = {gameSetup.playersNumbers}
                handleChoosePlayerNumber = {handleChoosePlayerNumber}
                selectedPlayerNumber = {gameSetup.selectedPlayerNumber}
                currentStep = {currentStep}
                currentlySearchingPLayersNumbers = {gameSetup.playersSearching}
              />
            )}
            {/* {showExtraStep && currentStep === 3 && (
              <VersusStep 
                selectedVersus = {gameSetup.selectedVersus}
                handleVersusSet = {handleVersusSet}
                currentStep = {currentStep}
              />
            )}
            {((showExtraStep && currentStep === 4) || (!(showExtraStep) && currentStep === 3)) && (
              <RulesStep
                availableRules = {gameSetup.avilableRules}
                selectedGameMode = {gameSetup.selectedGameMode}
                currentStep = {currentStep}
                onRuleChange = {onRuleChange}
                selectedRules = {gameSetup.selectedRules}
                currentLang = {currentLang}
              />
            )} */}
          </div>
        </div>
      </div>
      <div className = {`${commonStyles.bottom_part} ${extended() && commonStyles.extended}`}>
        <div className = {commonStyles.left_side}></div>
        <div className = {commonStyles.center}>
          <div className = {commonStyles.setpper_container}>
            <div 
              className = {`${commonStyles.stepper_part} ${currentStep === 1 ? commonStyles.current : ''} ${currentStep > 1 ? commonStyles.active : ''} ${commonStyles.circles}`}
              onClick = {() => {
                handleChoosePlayerNumber('')
                handleVersusSet(null)
                setShowExtraStep(false)
                onResetRules()
                setNextStep(1)
              }}
              >
              <h5 className = {commonStyles.stepper_title}>{t('wizard:online_game.mode')}</h5>
              <div className = {commonStyles.stepper_circle}></div>
              <h5 className = {commonStyles.setpper_value}>{gameSetup.selectedGameMode?.label ? t(`wizard:online_game.${gameSetup.selectedGameMode?.label?.toLowerCase()}`) : ''}</h5>
            </div>
            <div className = {`${commonStyles.stepper_part} ${currentStep > 1 ? commonStyles.active : ''} ${commonStyles.line}`}>
              <div className = {commonStyles.stepper_line}></div>
            </div>

            <div 
              className = {`${commonStyles.stepper_part} ${currentStep === 2 ? commonStyles.current : ''} ${currentStep > 2 ? commonStyles.active : ''} ${commonStyles.circles}`}
              onClick = {() => {
                if (gameSetup.selectedGameMode) {
                  handleVersusSet(null)
                  setShowExtraStep(false)
                  onResetRules()
                  setNextStep(2)
                }
              }}
              >
              <h5 className = {commonStyles.stepper_title}>{t('wizard:online_game.players')}</h5>
              <div className = {commonStyles.stepper_circle}></div>
              <h5 className = {commonStyles.setpper_value}>{gameSetup.selectedPlayerNumber}</h5>
            </div>
            {/* <div className = {`${commonStyles.stepper_part} ${currentStep > 2 ? commonStyles.active : ''} ${commonStyles.line}`}>
              <div className = {commonStyles.stepper_line}></div>
            </div> */}

            {/* {gameSetup.selectedPlayerNumber === 6 && (
              <>
                <div 
                  className = {`${commonStyles.stepper_part} ${(showExtraStep && currentStep > 3) || (!showExtraStep && currentStep > 2) ? commonStyles.active : ''} ${commonStyles.circles}`}
                  onClick = {() => {
                    if (showExtraStep) {
                      onResetRules()
                      setNextStep(3)
                    }
                  }}
                  >
                  <h5 className = {commonStyles.stepper_title}>Versus</h5>
                  <div className = {commonStyles.stepper_circle}></div>
                  <h5 className = {commonStyles.setpper_value}>{gameSetup.selectedVersus?.label}</h5>
                </div>
                <div className = {`${commonStyles.stepper_part} ${(showExtraStep && currentStep === 3) || (!showExtraStep && currentStep === 3) ? commonStyles.current : ''} ${(showExtraStep && currentStep > 3) || (!showExtraStep && currentStep > 2) ? commonStyles.active : ''} ${commonStyles.line}`}>
                  <div className = {commonStyles.stepper_line}></div>
                </div>
              </>
            )} */}

            {/* <div className = {`${commonStyles.stepper_part} ${commonStyles.circles} ${((showExtraStep && currentStep === 4) || (!(showExtraStep) && currentStep === 3)) ? (commonStyles.active + ' ' + commonStyles.filled) : ''}`}>
              <h5 className = {commonStyles.stepper_title}>{translates ? translates.step_rules : ''}</h5>
              <div className = {commonStyles.stepper_circle}>
                <div className = {commonStyles.checkmark}>
                  <CheckSvg />
                </div>
              </div>
              <h5 className = {commonStyles.setpper_value}>Solo</h5>
            </div> */}
          </div>
        </div>
          {/* {(((showExtraStep && currentStep === 4) || (!(showExtraStep) && currentStep === 3)) && !fromLobby)
            && (
              <div className = {commonStyles.right_side}>
                <div className = {`${commonStyles.search_game_container}`}>
                <button
                  className = {`${commonStyles.search_game}`} type="button"
                  onClick = {startMatchMaking}
                  disabled={isSearchDisabled}
                >
                  {buttonTranslates? buttonTranslates.search_game : ''}
                </button>
                </div>
              </div>
            )
          } */}
      </div>
    </div>
  )
}

export default PublicMM