import FormInput from "../FormInput/FormInput";
import * as commonStyles from '../../styles/scss/components/NewWizard/WizardCommon.module.scss';
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import useTranslation from 'next-translate/useTranslation'
import { useState } from "react";

const NameStep = (props) => {
  const {t} = useTranslation();
  const {gameName, setGameName} = props
  const router = useRouter();
  return (
    <div className = {`${commonStyles.buttons_container} ${commonStyles.game_name}`}>
      <h2 className = {commonStyles.title}>{t('wizard:friends_game.game_name_step_text')}</h2>
      <div className = {commonStyles.form_group}>
        <FormInput
          type = 'text'
          value = {gameName}
          name = 'gameName'
          change = {(e) => {setGameName(e.target.value)}}
          active = {gameName.length > 0}
          labelText = {t('common:inputs.update_game_name.label')}
          showIndicator = {false}
          id = 'gameName'
          changed={true}
          errorText={t('common:inputs.update_game_name.error_text')}
          valid={gameName.length > 0}
        // showIcon = {true}
        />
      </div>
    </div>
  )
}

export default NameStep