import * as commonStyles from '../../styles/scss/components/NewWizard/WizardCommon.module.scss';
import useTranslation from 'next-translate/useTranslation';
const ModeStep = (props) => {
  const {
    gameModes, 
    handLeGameModeChoose,
    selectedGameMode,
    currentStep,
    currentlySearchingPLayersNumber
  } = props

  const {t} = useTranslation()

  return (
    <div className = {`${commonStyles.buttons_container} ${commonStyles.mode_container}`}>
      <div className = {commonStyles.inner_container}>
        {!!gameModes && gameModes.map(gameMode =>{
          return (
            <div 
              className  = {`${commonStyles.button_container} ${commonStyles.mode} ${selectedGameMode && selectedGameMode.value === gameMode.value ? commonStyles.active : ''}`}
              key = {`gameMode${gameMode.value}`}
              onClick = {() => {handLeGameModeChoose(gameMode, currentStep + 1)}}
              >
              <div className = {`${commonStyles.standart_background} ${commonStyles.container}`}></div>
              <div className = {`${commonStyles.hover_background} ${commonStyles.container}`}></div>
              <div className = {`${commonStyles.active_background} ${commonStyles.container}`}></div>
              <div className = {`${commonStyles.content_container} ${commonStyles.container}`}>
                <div className = {commonStyles.image_container}>
                  <img src={`/images/rules/${gameMode.value}-dog.svg`} />
                </div>
                <div className = {commonStyles.text_container}>
                  <h5>{t(`wizard:online_game.${gameMode.label.toLowerCase()}`)}</h5>
                </div>
                <div className = {commonStyles.searching_progress_players}>
                  <div className = {commonStyles.icon_container}>
                    <img src = '/images/common/players-icon.svg' alt='searching icon' />
                  </div>
                  <div className = {`${commonStyles.numbers_container} ${currentlySearchingPLayersNumber[gameMode.value] == 0 ? commonStyles.disabled : ''}`}>
                    <span className = {`${commonStyles.player_number} ${commonStyles.current_number}`}>{currentlySearchingPLayersNumber[gameMode.value]}</span>
                  </div>
                </div>
              </div>
            </div>  
          )
        })}
      </div>
    </div>
  )
}
export default ModeStep